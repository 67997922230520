<msep-page-title [forceBackgroundImageUse]="true">
  <div *ngIf="isAuthenticated(); else unauthenticated">
    <h1>Partner Directory</h1>
  </div>
  <ng-template #unauthenticated>
    <h1>Our Partners</h1>
    <h2>
      A comprehensive list of all companies and organizations participating in
      the MSEP program.
    </h2>
  </ng-template>

  <mat-card [class]="isAuthenticated() ? 'flex-70' : ''">
    <mat-card-content>
      <form
        [formGroup]="form"
        class="global-search-form-fields flex flex-wrap flex-row justify-between align-center full-width-height"
      >
        <msep-partner-autocomplete
          class="flex flex-100"
          [class]="isAuthenticated() ? 'flex-gt-sm-100' : 'flex-gt-sm-48'"
          [formGroup]="form"
          formControlName="name"
          [overrideShowApprovedActiveOnly]="true"
          (partnerSelected)="onPartnerSelected($event)"
        ></msep-partner-autocomplete>
        <mat-form-field
          class="flex flex-100"
          [class]="
            isAuthenticated() ? 'flex-gt-md-100 flex-gt-lg-32' : 'flex-gt-sm-48'
          "
          appearance="outline"
        >
          <mat-label>Select Industry Sector</mat-label>
          <mat-select
            formControlName="industrySectorId"
            (selectionChange)="onIndustrySectorChanged($event)"
          >
            <mat-option [value]="null">All</mat-option>
            <mat-option
              *ngFor="let industrySector of industrySectors$ | async"
              [value]="industrySector.id"
            >
              {{ industrySector.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="flex flex-100"
          [class]="
            isAuthenticated() ? 'flex-gt-md-48 flex-gt-lg-32' : 'flex-gt-sm-48'
          "
          appearance="outline"
          *ngIf="isAuthenticated()"
        >
          <mat-label>Select Partnership Type</mat-label>
          <mat-select
            formControlName="partnershipTypeId"
            (selectionChange)="onPartnershipTypeChanged($event)"
          >
            <mat-option [value]="null">All</mat-option>
            <mat-option
              *ngFor="let partnershipType of partnershipTypes$ | async"
              [value]="partnershipType.id"
            >
              {{ partnershipType.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="flex flex-100 flex-gt-md-48 flex-gt-lg-32"
          appearance="outline"
          *ngIf="isAuthenticated()"
        >
          <mat-label>Select State</mat-label>
          <mat-select
            formControlName="stateId"
            (selectionChange)="onStateChanged($event)"
          >
            <mat-option [value]="null">All</mat-option>
            <mat-option
              *ngFor="let state of states$ | async"
              [value]="state.value"
            >
              {{ state.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="flex flex-100 align-start flex-col flex-gt-sm-row">
          <div
            class="flex flex-100 flex-row flex-wrap justify-between grid-gap-5"
            [class]="isAuthenticated() ? 'flex-gt-sm-48' : 'flex-fill'"
          >
            <div
              [class]="
                isAuthenticated() ? 'flex-100' : 'flex-100 flex-gt-xs-50'
              "
            >
              <mat-checkbox
                formControlName="isTeleworkAvailable"
                (change)="onTeleworkChange($event)"
              >
                Show Telework Partners
                <mat-icon
                  aria-label="Partner offers telework positions"
                  style="vertical-align: middle"
                >
                  home_work
                </mat-icon>
              </mat-checkbox>
            </div>
            <div
              [class]="
                isAuthenticated() ? 'flex-100' : 'flex-100 flex-gt-xs-50'
              "
            >
              <mat-checkbox
                formControlName="offersPermanentRemotePositions"
                (change)="onRemoteChange($event)"
              >
                Show Remote Partners
                <mat-icon
                  aria-label="Partner offers remote positions"
                  style="vertical-align: middle"
                >
                  wifi
                </mat-icon>
              </mat-checkbox>
            </div>
          </div>
          <div
            class="international-facilities flex-100 flex-fill flex-col align-start flex-gt-sm-52 col-gap-7"
            *ngIf="isAuthenticated()"
          >
            <div class="flex-100">
              <mat-checkbox
                formControlName="internationalFacilities"
                (change)="hasInternationalFacilities($event)"
              >
                Show Partners with International Facilities
              </mat-checkbox>
              <mat-icon
                  aria-label="Partner has international facilities"
                  style="vertical-align: middle"
                >
                  public
                </mat-icon>
            </div>
            <mat-form-field class="flex-100 flex-fill" appearance="outline">
              <mat-label>Select Country</mat-label>
              <mat-select
                formControlName="countryId"
                (selectionChange)="onCountryChanged($event)"
              >
                <mat-option [value]="null">All</mat-option>
                <mat-option
                  *ngFor="let country of countries$ | async"
                  [value]="country.id"
                >
                  {{ country.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="action-buttons flex-100">
          <button
            mat-raised-button
            type="reset"
            (click)="onReset()"
            role="button"
          >
            Reset
          </button>
        </div>
      </form>
    </mat-card-content>
    <msep-card-loader [isLoading]="isLoading"></msep-card-loader>
  </mat-card>
</msep-page-title>

<div *ngIf="partners$ | async" class="margin-top-20">
  <ng-container *ngIf="resultTotal > 0; else noResults">
    <div
      class="flex flex-col flex-wrap flex-gt-sm-row justify-between grid-gap-16"
    >
      <div
        class="flex-100 flex-gt-sm-32 flex-gt-lg-19 col-gap-16"
        *ngFor="let partner of partners"
      >
        <mat-card matRipple class="partner-card">
          <a role="link" [routerLink]="['/our-partners', partner.id]">
            <div class="partner-card-info">
              <div class="image-container flex justify-center align-center">
                <msep-partner-logo
                  [logoUrl]="partner.logoUrl"
                  [organizationName]="partner.organizationName"
                ></msep-partner-logo>
              </div>
              <div>
                <mat-card-title>{{ partner.organizationName }}</mat-card-title>
                <mat-card-subtitle>
                  {{ partner.primaryIndustrySector }}
                  <div>
                    Partner since
                    <span>{{ partner.approvedDate | date : 'YYYY' }} </span>
                  </div>
                </mat-card-subtitle>
              </div>
            </div>
            <mat-card-footer>
              <div class="flex">
                <div class="flex flex-60 align-end">
                  <strong>
                    {{ partner.numberOfActiveJobs | number }}
                    Posted Jobs
                  </strong>
                </div>
                <div class="flex flex-40 justify-end">
                  <div
                    class="flex flex-30 justify-end"
                    *ngIf="partner.isTeleworkAvailable"
                  >
                    <mat-icon
                      matTooltip="Partner offers telework positions"
                      matTooltipClass="tooltip"
                      aria-label="Partner offers telework positions"
                    >
                      home_work
                    </mat-icon>
                  </div>
                  <div
                    class="flex flex-30 justify-end"
                    *ngIf="partner.offersPermanentRemotePositions"
                  >
                    <mat-icon
                      matTooltip="Partner offers remote positions"
                      matTooltipClass="tooltip"
                      aria-label="Partner offers remote positions"
                    >
                      wifi
                    </mat-icon>
                  </div>
                  <div
                    class="flex flex-30 justify-end"
                    *ngIf="partner.isInternational"
                  >
                    <mat-icon
                      matTooltip="Partner has international facilities"
                      matTooltipClass="tooltip"
                      aria-label="Partner has international facilities"
                    >
                      public
                    </mat-icon>
                  </div>
                  <div
                    class="flex flex-30 justify-end"
                    *ngIf="partner.offersChildcare"
                  >
                    <mat-icon
                      matTooltip="Partner offers childcare benefits"
                      matTooltipClass="tooltip"
                      aria-label="Partner offers childcare benefits"
                    >
                      child_friendly
                    </mat-icon>
                  </div>
                </div>
              </div>
            </mat-card-footer>
          </a>
        </mat-card>
      </div>
    </div>
    <msep-pagination
      #paginator
      [pageSize]="pageSize"
      [length]="resultTotal"
      (pagingChange)="onPagingChange($event)"
    ></msep-pagination>
  </ng-container>
  <ng-template #noResults>
    <msep-no-results></msep-no-results>
  </ng-template>
</div>

<msep-ghost-loader-list
  class="row-in-column flex flex-wrap flex-row justify-between grid-gap-8 grid-gap-gt-sm-16"
  [type]="ghostLoaderType"
  [ghostLength]="pageSize"
  [childFlex]="100"
  [childFlexGtSm]="32"
  [childFlexGtLg]="19"
  *ngIf="!partners"
></msep-ghost-loader-list>
