<msep-page-title>
  <h1>Help</h1>
</msep-page-title>

<section>
  <h2>How can I get more information on joining MSEP?</h2>
  <p>
    For more information on the Military Spouse Employment Partnership (MSEP)
    and joining the partnership, please view our
    <a role="link" [routerLink]="['/become-a-partner']">Become a Partner</a> and
    <a role="link" [routerLink]="['/partner-resources']">Partner Resources</a>
    pages. If you have any other questions or would like further assistance,
    feel free to call the MSEP program staff at
    <a role="link" href="tel:877-940-6737">877-940-MSEP (6737)</a>.
  </p>
</section>

<section>
  <h2>
    My company is an MSEP Partner. How do I register for access to the MSEP
    Portal?
  </h2>
  <p>
    To register for an account with an existing MSEP Partner, contact your
    organization's primary POC and ask them to send you an invitation to
    register via MSEP's user management page. If you need further assistance,
    contact our
    <a role="link" [routerLink]="['/notice-and-consent', 'contact-us']"
      >MSEP Support Team</a
    >.
  </p>
</section>

<section>
  <h2>Are you a military spouse?</h2>
  <p>
    <a
      role="link"
      (click)="onClickSeco()"
      aria-label="Search for jobs, SECO Portal Jobs - link opens in a new window"
      style="cursor: pointer"
      >Search for jobs</a
    >
    with MSEP Partners. Find employment opportunities with hundreds of companies
    and organizations committed to recruiting, hiring, promoting and retaining
    military spouses in portable careers. Call
    <a role="link" href="tel:800-342-9647">800-342-9647</a>.
  </p>
</section>

<section>
  <h2>Need additional help?</h2>
  <p>
    To send us your comments or report any technical difficulties you experience
    with our website,
    <a role="link" [routerLink]="['/notice-and-consent', 'contact-us']"
      >contact us</a
    >.
  </p>
</section>
